








































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import {
  CommonStore as commonstore,
  MetaModule as metaStore,
  UserRegistrationModule as userStore,
} from "@/store/modules";
import { District, Palika, UserType } from "@/store/models/meta";
import { Route } from "vue-router";

@Component({
  components: {
    Input,
    Button,
    Form,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  municipality: Palika[] = [];
  districts: District[] = [];
  routeList: number[] = [];
  status: string = "";
  async created() {
    let vm = this;
    await metaStore.loadProvince();
    await metaStore.loadUserTypesDD();
    await metaStore.loadRoutesDD();
    const id = vm.$route.query["id"];

    if (id) {
      this.status = "edit";
      vm.isCreate = false;
      await userStore.getUserById(id);
      this.routeList = this.users.systemUserDetail.routes;
      if (this.users.systemUserDetail && !this.users.systemUserDetail.geom) {
        this.users.systemUserDetail.geom = {
          latitude: "",
          longitude: "",
        };
      }
    } else {
      userStore.resetField();
      this.$validator.reset();
    }
    if (this.users.profile.province) {
      this.districts = await metaStore.loadDistrict(
        this.users.profile.province
      );
    }

    if (this.users.profile.district) {
      this.municipality = await metaStore.loadMuncipality(
        this.users.profile.district
      );
    }
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get routes() {
    return metaStore.routesList;
  }

  get usersRole() {
    return metaStore.userTypeList;
  }

  get users() {
    return userStore.user;
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();
    this.users.systemUserDetail.routes = this.routeList;
    await userStore.createUser();
    this.$snotify.success("Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.UserList);
  }

  async cancelForm() {
    this.$router.push(AdminRouter.UserList);
  }

  async onProvinceChange(e: any) {
    this.districts = await metaStore.loadDistrict(e);
  }

  async onDistrictChange(e: any) {
    this.municipality = await metaStore.loadMuncipality(e);
  }
  get province() {
    return metaStore.provinceList;
  }

  mounted() {}
  updated() {}
}
