var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"flex verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_c('h3',[_vm._v("User Registration Form")])]),_c('div',{staticClass:"textRight"},[_c('router-link',{attrs:{"to":_vm.AdminRouter.UserList}},[_c('el-button',{attrs:{"type":"primary el-button--mini","plain":"","round":""}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_vm._v(" Back ")])],1)],1)]),_c('div',[_c('div',[_c('el-form',{attrs:{"label-position":'left',"label-width":"150px","multiple":""}},[_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Username'),
                    'is-required': _vm.isFieldRequired('Username'),
                  },attrs:{"label":"Username"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Username","name":"Username"},model:{value:(_vm.users.username),callback:function ($$v) {_vm.$set(_vm.users, "username", $$v)},expression:"users.username"}}),(_vm.errors.has('Username'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Username")))]):_vm._e()],1),_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Email'),
                    'is-required': _vm.isFieldRequired('Email'),
                  },attrs:{"label":"Email"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Email","name":"Email"},model:{value:(_vm.users.email),callback:function ($$v) {_vm.$set(_vm.users, "email", $$v)},expression:"users.email"}}),(_vm.errors.has('Email'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Email")))]):_vm._e()],1),(_vm.status !== 'edit')?_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Password'),
                    'is-required': _vm.isFieldRequired('Password'),
                  },attrs:{"label":"Password"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"Password",attrs:{"placeholder":"Password","type":"password","name":"Password"},model:{value:(_vm.users.password),callback:function ($$v) {_vm.$set(_vm.users, "password", $$v)},expression:"users.password"}}),(_vm.errors.has('Password'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Password")))]):_vm._e()],1):_vm._e(),(_vm.status !== 'edit')?_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Confirm password'),
                    'is-required': _vm.isFieldRequired('Confirm password'),
                  },attrs:{"label":"Confirm Password"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:Password'),expression:"'required|confirmed:Password'"}],attrs:{"placeholder":"Confirm Password","type":"password","name":"Confirm password","data-vv-as":"Password"},model:{value:(_vm.users.password2),callback:function ($$v) {_vm.$set(_vm.users, "password2", $$v)},expression:"users.password2"}}),(_vm.errors.has('Confirm password'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Confirm password")))]):_vm._e()],1):_vm._e(),(_vm.users.profile)?_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('role'),
                    'is-required': _vm.isFieldRequired('role'),
                  },attrs:{"label":"Role"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select","name":"role"},model:{value:(_vm.users.profile.userType),callback:function ($$v) {_vm.$set(_vm.users.profile, "userType", $$v)},expression:"users.profile.userType"}},_vm._l((_vm.usersRole),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),(_vm.errors.has('role'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("role")))]):_vm._e()],1):_vm._e(),_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors(''),
                    'is-required': _vm.isFieldRequired(''),
                  },attrs:{"label":"Routes"}},[_c('el-checkbox-group',{model:{value:(_vm.routeList),callback:function ($$v) {_vm.routeList=$$v},expression:"routeList"}},_vm._l((_vm.routes),function(item,k){return _c('el-checkbox',{key:k,attrs:{"label":item.gid,"value":item.gid}},[_vm._v(_vm._s(item.name))])}),1)],1),(_vm.users.profile)?_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Permanent Address ")]),_c('el-row',{attrs:{"gutter":30}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                          'is-error': _vm.fieldHasErrors('Province'),
                          'is-required': _vm.isFieldRequired('Province'),
                        },attrs:{"label":"Province"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select Province","name":"Province","filterable":""},on:{"change":_vm.onProvinceChange},model:{value:(_vm.users.profile.province),callback:function ($$v) {_vm.$set(_vm.users.profile, "province", $$v)},expression:"users.profile.province"}},_vm._l((_vm.province),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Province'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Province")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                          'is-error': _vm.fieldHasErrors('District'),
                          'is-required': _vm.isFieldRequired('District'),
                        },attrs:{"label":"District"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select District","name":"District","filterable":""},on:{"change":_vm.onDistrictChange},model:{value:(_vm.users.profile.district),callback:function ($$v) {_vm.$set(_vm.users.profile, "district", $$v)},expression:"users.profile.district"}},_vm._l((_vm.districts),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('District'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("District")))]):_vm._e()],1)],1)],1),_c('el-row',{attrs:{"gutter":30}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                          'is-error': _vm.fieldHasErrors('Palika'),
                          'is-required': _vm.isFieldRequired('Palika'),
                        },attrs:{"label":"Municipality"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select Palika","name":"Palika","filterable":""},model:{value:(_vm.users.profile.palika),callback:function ($$v) {_vm.$set(_vm.users.profile, "palika", $$v)},expression:"users.profile.palika"}},_vm._l((_vm.municipality),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Palika'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Palika")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                          'is-error': _vm.fieldHasErrors('Ward Number'),
                          'is-required': _vm.isFieldRequired('Ward Number'),
                        },attrs:{"label":"Ward Number"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Ward Number","name":"Ward Number"},model:{value:(_vm.users.profile.wardNo),callback:function ($$v) {_vm.$set(_vm.users.profile, "wardNo", $$v)},expression:"users.profile.wardNo"}}),(_vm.errors.has('Ward Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Ward Number")))]):_vm._e()],1)],1)],1),_c('el-row',{attrs:{"gutter":30}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                          'is-error': _vm.fieldHasErrors('Tole'),
                          'is-required': _vm.isFieldRequired('Tole'),
                        },attrs:{"label":"Tole/Street"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Tole/Street","name":"Tole"},model:{value:(_vm.users.profile.tole),callback:function ($$v) {_vm.$set(_vm.users.profile, "tole", $$v)},expression:"users.profile.tole"}}),(_vm.errors.has('Tole'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Tole")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                          'is-error': _vm.fieldHasErrors('Block Number'),
                          'is-required': _vm.isFieldRequired('Block Number'),
                        },attrs:{"label":"Block Number"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Block Number","name":"Block Number"},model:{value:(_vm.users.profile.blockNo),callback:function ($$v) {_vm.$set(_vm.users.profile, "blockNo", $$v)},expression:"users.profile.blockNo"}}),(_vm.errors.has('Block Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Block Number")))]):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.users.systemUserDetail && _vm.users.systemUserDetail.geom)?_c('el-row',[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors(''),
                      'is-required': _vm.isFieldRequired(''),
                    },attrs:{"label":"Latitude"}},[_c('el-input',{attrs:{"placeholder":"Latitude","name":"Latitude"},model:{value:(_vm.users.systemUserDetail.geom.latitude),callback:function ($$v) {_vm.$set(_vm.users.systemUserDetail.geom, "latitude", $$v)},expression:"users.systemUserDetail.geom.latitude"}}),(_vm.errors.has('Latitude'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Latitude")))]):_vm._e()],1),_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors(''),
                      'is-required': _vm.isFieldRequired(''),
                    },attrs:{"label":"Longitude"}},[_c('el-input',{attrs:{"placeholder":"Longitude","name":"Longitude"},model:{value:(_vm.users.systemUserDetail.geom.longitude),callback:function ($$v) {_vm.$set(_vm.users.systemUserDetail.geom, "longitude", $$v)},expression:"users.systemUserDetail.geom.longitude"}}),(_vm.errors.has('Longitude'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Longitude")))]):_vm._e()],1)],1):_vm._e(),_c('el-row',[_c('el-col',[_c('div',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":_vm.cancelForm}},[_vm._v("Cancel")])],1)])],1)],1)],1)])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }